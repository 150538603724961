import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Space, Select, Upload } from "antd";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { getProduct, EditProduct, dummyRequest, normFile, uploadFile } from "../util/functions";
import { SearchComponent } from "./SearchComponent";
import { ProductTypesContext } from '../contexts/ProductTypes';
import { toast } from "react-hot-toast";
const { TextArea } = Input;

export const EditProductComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { productTypes } = useContext(ProductTypesContext);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (product) return;
    const fetchProduct = async () => {
      const fetchedProduct = await getProduct(id);
      if (!fetchedProduct) {
        navigate("/products");
        toast.error("Product not found");
        return;
      }
      setProduct(fetchedProduct);
    };
    fetchProduct();
  }, [product, id, navigate]);

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        product_type: product.type_name,
        source: product.source,
        brand: product.brand,
        specs: product.specs,
        dimensions: product.dimensions,
        dimensions_unit: product.dimensions_unit,
        weight: product.weight,
        weight_unit: product.weight_unit,
        productDetails: product.details,
        note: product.note,
      });
    }
  }, [product, form]);

  const onFinish = async (values) => {
    try{
      if (loading) return;
      setLoading(true);
      let imgFilename = null;
      console.log(values)
      if(values.image?.length > 0) {
        imgFilename = await uploadFile(values.image[0].originFileObj);
      }
      await EditProduct(values, productTypes, id, imgFilename);
      toast.success('Edit Success')
      navigate(`/product/${id}`);
    } catch (error) {
      console.log(error);
      navigate(`/products`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ height: "100%" }} className="content-card-container">
      <h1>Add Product</h1>
      <Form
        layout="vertical"
        name="add-product-form"
        initialValues={{ productDetails: product?.product_details || [""] }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item>
          <Form.Item
            label="Product Type"
            name="product_type"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0",
            }}
            trigger="onSelect"
            validateTrigger="onSelect"
            rules={[
              {
                required: true,
                message: "Please input product type!",
              },
            ]}
          >
            <SearchComponent showOptions={true} size={"middle"} width={'100%'}/>
          </Form.Item>

          <Form.Item
            label="Source"
            name="source"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
            rules={[
              { required: true, message: "Please input product source!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0",
            }}
            label="Brand"
            name="brand"
          >
            <Input placeholder="E.g. Total, Makita, Bosch..." />
          </Form.Item>

          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
            label="Specs"
            name="specs"
            // rules={[{ required: true, message: "Please input product specs!" }]}
          >
            <Input placeholder="240V" />
          </Form.Item>
        </Form.Item>

        <Form.List name="productDetails">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div
                  key={field.key}
                  className="product-details-form"
                >
                  <Form.Item
                    {...field}
                    className="product-details-form-item"
                    name={[field.name, "source"]}
                    rules={[{ required: true, message: "Missing source" }]}
                  >
                    <Input placeholder="Source" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    className="product-details-form-item"
                    name={[field.name, "cost"]}
                    rules={[{ required: true, message: "Missing cost" }]}
                  >
                    <Input placeholder="Cost" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    className="product-details-form-item"
                    name={[field.name, "price"]}
                    rules={[{ required: true, message: "Missing price" }]}
                  >
                    <Input placeholder="Price" />
                  </Form.Item>

                  <Form.Item className="product-details-form-item">
                    <Space.Compact block>
                      <Form.Item
                        name={[field.name, "dimensions"]}
                        style={{ marginBottom: 0, width: "100%"}}
                        className="product-details-form-subitem"
                        rules={[
                          {
                            required: true,
                            message: "Missing dimensions!",
                          },
                        ]}
                      >
                        <Input placeholder="Dimensions" />
                      </Form.Item>

                      <Form.Item
                        name={[field.name, "dimensions_unit"]}
                        style={{ marginBottom: 0 }}
                        className="product-details-form-subitem"
                        rules={[
                          {
                            required: true,
                            message: "Missing unit!",
                          },
                        ]}
                      >
                        <Select placeholder="unit">
                          <Select.Option value="m">m</Select.Option>
                          <Select.Option value="cm">cm</Select.Option>
                          <Select.Option value="mm">mm</Select.Option>
                          <Select.Option value="in">in</Select.Option>
                        </Select>
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>

                  <Form.Item className="product-details-form-item">
                    <Space.Compact block>
                      <Form.Item
                        name={[field.name, "weight"]}
                        style={{ marginBottom: 0, width: "100%" }}
                        className="product-details-form-subitem"
                        rules={[
                          {
                            required: true,
                            message: "Missing weight!",
                          },
                        ]}
                      >
                        <Input placeholder="Weight" />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "weight_unit"]}
                        style={{ marginBottom: 0 }}
                        className="product-details-form-subitem"
                        rules={[
                          {
                            required: true,
                            message: "Missing unit!",
                          },
                        ]}
                      >
                        <Select placeholder="unit">
                          <Select.Option value="kg">kg</Select.Option>
                          <Select.Option value="g">g</Select.Option>
                        </Select>
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>

                  {index > 0 ? (
                    <div className="minus-circle-form-container">
                      <MinusCircleOutlined
                        style={{ fontSize: 20, color: "#ff4d4f" }}
                        onClick={() => remove(field.name)}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={add}
                  block
                  icon={<PlusOutlined />}
                >
                Add Source
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item label="Note" name="note">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name={'image'}
          label="Product Image"
          getValueFromEvent={normFile}
        >
          <Upload
            accept='image/*'
            maxCount={1}
            listType="picture"
            customRequest={dummyRequest}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-btn"
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
