import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { ProductTypesComponent } from "./components/ProductTypesComponent";
import {AddProductComponent} from "./components/AddProductComponent";
import {AddCategoryComponent} from "./components/AddCategoryComponent";
import { SidebarComponent } from "./components/SidebarComponent";
import { FooterComponent } from "./components/FooterComponent";
import { ProductTypesProvider } from "./contexts/ProductTypes";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { RedirectToCategories } from "./components/RedirectToCategories";
import { CategoryProductsComponent } from "./components/CategoryProductsComponent";
import { ProductComponent } from "./components/ProductComponent";
import { AllProductsComponent } from "./components/AllProductsComponent";
import { EditProductComponent } from "./components/EditProductComponent";
import LoginComponent from "./components/LoginComponent";
import { Toaster } from "react-hot-toast";
import "./App.css";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div><Toaster /></div>
        <Routes>
          <Route path="/login" element={<LoginComponent />} />
          <Route path="*" element={<ProtectedApp />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

const ProtectedApp = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <ProductTypesProvider>
      <Layout style={{ minHeight: "100vh" }}>
        <SidebarComponent />
        <Layout>
          <Content className="content-container">
            <Routes>
              <Route exact path="/" element={<RedirectToCategories />} />
              <Route path="/categories" element={<ProductTypesComponent />} />
              <Route path="/categories/:id" element={<CategoryProductsComponent />} />
              <Route path="/product/:id" element={<ProductComponent />} />
              <Route path="/product/:id/edit" element={<EditProductComponent />} />
              <Route path="/add/product" element={<AddProductComponent />} />
              <Route path="/add/category" element={<AddCategoryComponent />} />
              <Route path="/products" element={<AllProductsComponent />} />
            </Routes>
          </Content>
          <FooterComponent />
        </Layout>
      </Layout>
    </ProductTypesProvider>
  );
};
export default App;
