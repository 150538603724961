import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export async function login(password) {
  const {data} = await axios.post('/login', { password });
  if (data.status === "success") {
    return data.token;
  }
  return null;
}

export async function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    const { data: { filename } } = await axios.post('/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return filename || null;
}

export async function addCategory(category) {
  const { data: { id } } = await axios.post('/add-category', {category});
  return id || null;
}

export async function addCategoryImage(categoryId, filename) {
  const { data: { data: serverData } } = await axios.post('/add-category-image',
  {
    categoryId,
    filename,
  });

  return serverData?.id || null;
}

export async function getProductTypes() {
  const { data: { data: serverData } } = await axios('/get-categories');
  return serverData
}

export function getImgUrl(filename) {
  return `${process.env.REACT_APP_CDN_URL}/images/${filename}`;
}

export function getProductTypeFilename(product, productTypes) {
    let filename = null;
    productTypes.find((pt) => {
        if (pt.id === product.product_type_id) {
            filename = pt.product_types_images?.[0]?.filename;
        }
        return null;
    });
    return filename;
}


export async function getProductsBySearch(searchTerm, range, categoryId=null) {
    // convert range to offset and limit
    const offset = range.from;
    const limit = range.to - range.from + 1;
    const payload = {
      searchTerm,
      limit,
      offset,
    }

    if (categoryId) {
      payload.categoryId = categoryId;
    }
    const { data: { data: serverData } } = await axios.post("/search-products", payload)

    if (!serverData || !serverData.length) return null;

    serverData.forEach((product) => {
        let title = product.product_type_name;
        if (product.brand) title += ` - ${product.brand}`;
        product.title = title;
        delete product.total_count;
    });

    return serverData;
}

export async function getProduct(productId) {
  const { data: { data: serverData } } = await axios.post('/get-product', {id: productId});

    let title = serverData.type_name;
    if (serverData.brand) title += ` - ${serverData.brand}`;
    serverData.title = title;

    return serverData;
}

export async function AddProduct(formData, productTypes, imgFilename) {
  let productTypeId = null;
  if (formData.product_type) {
    productTypeId = productTypes.find(
      (productType) => productType.name === formData.product_type
    ).id;
  }

  const productDetails = formData.productDetails.map((productDetail) => {
    return {
      source: productDetail.source ? productDetail.source : null,
      cost: productDetail.cost ? productDetail.cost : null,
      price: productDetail.price ? productDetail.price : null,
      dimensions: productDetail.dimensions ? productDetail.dimensions : null,
      dimensions_unit: productDetail.dimensions_unit
        ? productDetail.dimensions_unit
        : null,
      weight: productDetail.weight ? productDetail.weight : null,
      weight_unit: productDetail.weight_unit ? productDetail.weight_unit : null,
    };
  });

  let payload = {
    type: productTypeId,
    source: formData.source ? formData.source : null,
    brand: formData.brand ? formData.brand : null,
    specs: formData.specs ? formData.specs : null,
    note: formData.note ? formData.note : null,
    image: imgFilename ? imgFilename : null,
    productDetails: productDetails.length ? productDetails : null,
  }

  const { data: { id } } = await axios.post('/add-product', payload);
  return id || null;
}

export async function EditProduct(formData, productTypes, productId, imgFilename) {
  let productTypeId = null;
  if (formData.product_type) {
    productTypeId = productTypes.find(
      (productType) => productType.name === formData.product_type
    ).id;
  }

  const updateObject = {
    id: productId,
    type: productTypeId,
    source: formData.source || null,
    brand: formData.brand || null,
    specs: formData.specs || null,
    note: formData.note || null,
  };

  if (imgFilename) {
    updateObject.image = imgFilename;
  }

  const productDetails = formData.productDetails.map((productDetail) => {
    return {
      source: productDetail.source ? productDetail.source : null,
      cost: productDetail.cost ? productDetail.cost : null,
      price: productDetail.price ? productDetail.price : null,
      dimensions: productDetail.dimensions ? productDetail.dimensions : null,
      dimensions_unit: productDetail.dimensions_unit
        ? productDetail.dimensions_unit
        : null,
      weight: productDetail.weight ? productDetail.weight : null,
      weight_unit: productDetail.weight_unit ? productDetail.weight_unit : null,
    };
  });

  updateObject.productDetails = productDetails;

  const { data: { data: serverData } } = await axios.post('/edit-product', updateObject);
  return serverData;
}

export async function deleteProduct(productId) {
  const { data: { data: serverData } } = await axios.post('/delete-product', {id: productId});
  return serverData;
}

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
      return e;
  }
  return e && e.fileList;
};