import React, { useContext, useState } from 'react';
import { Col, Row } from "antd";
import { ProductTypeCard } from "./ProductTypeCard";
import { ProductTypesContext } from '../contexts/ProductTypes';
import { getImgUrl } from '../util/functions';
import { SearchComponent } from './SearchComponent';

export const ProductTypesComponent = () => {
  const { productTypes, loading } = useContext(ProductTypesContext);
  const [searchValue, setSearchValue] = useState("");

  const filteredProductTypes = productTypes.filter((pt) => {
    if (!searchValue) return true;
    return pt.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className="content-card-container">
      <h1>Product Categories</h1>
      <SearchComponent
        size={'large'}
        width={'100%'}
        marginBottom={'1rem'}
        setSearchValue={setSearchValue}
        allowClear={true}
      />
      {loading ? ( // Check if loading
        <p>Loading...</p>
      ) : filteredProductTypes.length === 0 ? ( // Check if no products found
        <p>No Products Found</p>
      ) : (
        <Row gutter={[0, 16]} align={'middle'} justify={'center'}>
          {filteredProductTypes.map((productType) => {
            let imageUrl = getImgUrl(productType?.image);
            return (
              <Col
                className="product-type-card-container"
                key={productType.id}
                lg={8}
                xl={6}
                flex={1}
              >
                <ProductTypeCard
                  productType={productType}
                  imageUrl={imageUrl}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};
