import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile, addCategory, addCategoryImage, dummyRequest, normFile } from "../util/functions";
import { ProductTypesContext } from "../contexts/ProductTypes";
import { toast } from "react-hot-toast";

export const AddCategoryComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { fetchProductTypes } = useContext(ProductTypesContext);

  const onFinish = async (values) => {
    try{
      if (loading) return;
      setLoading(true);
      let id = await addCategory(values.category);
      if(values.category_img?.length > 0) {
        let filename = await uploadFile(values.category_img[0].originFileObj);
        if(!filename) {
          throw new Error('Upload Failed');
        }
        await addCategoryImage(id, filename);
      }
      await fetchProductTypes();
      toast.success('Add Success');
    } catch (error) {
      console.log(error);
      toast.error('Add Failed');
    } finally{
      setLoading(false);
      navigate(`/categories`);
    }
  };

  return (
    <div style={{ height: "100%" }} className="content-card-container">
      <h1>Add Category</h1>
      <Form
        layout="vertical"
        name="add-product-form"
        initialValues={{ productDetails: [""] }}
        onFinish={onFinish}
      >
        <Form.Item>
          <Form.Item
            label="Category"
            name="category"
            style={{
              display: "inline-block",
              width: "100%",
              margin: "0 8px",
            }}
            rules={[
              { required: true, message: "Please input product category!" },
            ]}
          >
            <Input placeholder="عفريت"/>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name={'category_img'}
          label="Category Image"
          getValueFromEvent={normFile}
        >
          <Upload
            accept='image/*'
            maxCount={1}
            listType="picture"
            customRequest={dummyRequest}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-btn"
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
