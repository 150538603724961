import { createContext, useContext, useState, useEffect } from 'react';
import { login } from '../util/functions';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkTokenValidity = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setLoading(false);
      return;
    }
    
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp && decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem('authToken');
        setUser(null);
      } else {
        setUser(decoded);
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      localStorage.removeItem('authToken');
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);

  const signIn = async (password) => {
    const token = await login(password);
    if (token) {
      localStorage.setItem('authToken', token);
      const decoded = jwtDecode(token);
      setUser(decoded);
      return { error: null };
    } else {
      setUser(null);
      return { error: 'Invalid password' };
    }
  };

  const signOut = () => {
    localStorage.removeItem('authToken');
    setUser(null);
  }

  const value = {
    signIn,
    signOut,
    user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
