import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PlusCircleOutlined,
  HomeOutlined,
  ToolOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

const { Sider } = Layout;

export const SidebarComponent = () => {
  const location = useLocation();

  const getSelectedKey = () => {
    switch (location.pathname) {
      case "/categories":
        return "1";
      case "/products":
        return "2";
      case "/add/product":
        return "3";
      default:
        return "";
    }
  };

  return (
    <Sider
    style={{
      zIndex: 1,
    }}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[getSelectedKey()]}
      >
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/categories">Categories</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<ToolOutlined />}>
          <Link to="/products">All Products</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<PlusCircleOutlined />}>
          <Link to="/add/product">Add Product</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<PlusCircleOutlined />}>
          <Link to="/add/category">Add Category</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
