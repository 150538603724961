import React, { useState, useEffect, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton, List, Avatar, Divider, Input } from "antd";
import { Link, useParams } from "react-router-dom";
import { getProductsBySearch, getImgUrl, getProductTypeFilename } from "../util/functions";
import { ProductTypesContext } from "../contexts/ProductTypes";

export const CategoryProductsComponent = (props) => {
  const { id: categoryId } = useParams();
  const { productTypes } = useContext(ProductTypesContext);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [range, setRange] = useState({
    from: 0,
    to: 19,
  });

  const getItemAvatar = (item) => {
    if (item.image) return getImgUrl(item.image);
    if(!productTypes || !productTypes.length) return;
    let filename = getProductTypeFilename(item, productTypes)
    let imageUrl = getImgUrl(filename)
    return imageUrl
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    setSearchLoading(true);
    let newRange = {
      from: 0,
      to: 19,
    };
    setRange(newRange);
    const newData = await getProductsBySearch(value, newRange, categoryId);
    if (!newData) {
      setData([]);
      setSearchLoading(false);
      setHasMore(false);
      return;
    }

    setRange((prevRange) => ({
      from: prevRange.to + 1,
      to: prevRange.to + 20,
    }));
    if (!newData.total_count || newData.length === newData.total_count) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setData(newData);
    setSearchLoading(false);
  };

  const loadMoreData = async () => {
    if (searchLoading) {
      return;
    }
    setSearchLoading(true);
    let newData = await getProductsBySearch(searchValue, range, categoryId);
    if (!newData) {
      setSearchLoading(false);
      setHasMore(false);
      return;
    }
    setRange((prevRange) => ({
      from: prevRange.to + 1,
      to: prevRange.to + 20,
    }));
    if (!newData.total_count || data.length + newData.length === newData.total_count) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setData([...data, ...newData]);
    setSearchLoading(false);
  };

  useEffect(() => {
    loadMoreData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="content-card-container">
    <div className="title-search-wrapper">
      <h1> Products </h1>
      <Input.Search
        style={{ width: '300px' }}
        loading={searchLoading}
        onSearch={handleSearch}
      />
    </div>
      <div
        id="scrollableDiv"
        style={{
          height: "calc(100vh - 210px)",
          overflow: "auto",
          borderRadius: "8px",
          background: "#ffffff",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <Link to={`/product/${item.product_id}`}>
                <div className="product-card-parent">
                  <List.Item key={item.product_id} className="product-card">
                    <List.Item.Meta
                      avatar={<Avatar size={"large"} src={getItemAvatar(item)} />}
                      title={item.title}
                      description={item.specs}
                    />
                    <div>{item.product_source}</div>
                  </List.Item>
                </div>
              </Link>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};
