import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectToCategories = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/categories');
    });

    return null;
};
