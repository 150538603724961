import React, { useState, useContext, useEffect } from 'react';
import { AutoComplete } from 'antd';
import { ProductTypesContext } from '../contexts/ProductTypes';

export const SearchComponent = ({ setSearchValue, showOptions, size, width, marginBottom, onSelect: onSelectExternal, value: valueExternal, allowClear }) => {
  const { productTypes, loading } = useContext(ProductTypesContext);
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(valueExternal || "");

  useEffect(() => {
    if (valueExternal) {
      setCurrentValue(valueExternal);
    }
  }, [valueExternal]);

  const handleSearch = (value) => {
    if (value) {
      const filteredResult = productTypes.filter(pt => pt.name.toLowerCase().includes(value.toLowerCase())).map(pt => ({
        value: pt.name,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {pt.name}
            </span>
          </div>
        ),
      }));

      setOptions(filteredResult);
    } else {
      setOptions([]);
    }
  };

  const handleFocus = () => {
    if (showOptions && !options.length) {
      const allProductTypes = productTypes.map(pt => ({
        value: pt.name,
        label: (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{pt.name}</span>
          </div>
        ),
      }));
      setOptions(allProductTypes);
    }
  };

  const handleChange = (value) => {
    if (setSearchValue) setSearchValue(value);
    setCurrentValue(value);
  };

  const handleSelect = (value) => {
    if (onSelectExternal) onSelectExternal(value);
    setCurrentValue(value);
  };

  const handleBlur = () => {
    if (showOptions) {
      setCurrentValue(valueExternal);
    }
  };

  const style = {}
  if (marginBottom) style.marginBottom = marginBottom;
  if (width) style.width = width;

  return (
    <AutoComplete
      style={style}
      value={currentValue}
      options={options}
      onChange={handleChange}
      onClear={handleChange}
      onSearch={handleSearch}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onSelect={handleSelect}
      allowClear={allowClear ? allowClear : false}
      disabled={loading}
      placeholder='Search..'
      size={size}
    >
    </AutoComplete>
  );
};
