import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

const LoginComponent = () => {
    const [loading, setLoading] = useState(false);
    const { signIn } = useAuth()
    const navigate = useNavigate();
    const handleSubmit = async (values) => {
        try {
            if (loading) return;
            setLoading(true);
            if (!values.password) return;
            const { error } =  await signIn(values.password);
            if (error) {
                console.error(error);
                toast.error('Login Failed');
                return;
            }
            toast.success('Login Success');
            navigate('/');
            // redirect to dashboard or main page
        } catch (error) {
            console.log(error);
            toast.error('Failed to log in. Please try again.');
        } finally {
            setLoading(false);
        }
    };

  return (
    <div style={{ maxWidth: '400px', height: '100%', margin: '0 auto', padding: '20px', display: 'flex', alignItems: 'center' }}>
      <Form onFinish={handleSubmit} style={{width: '100%'}}>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            style={{ width: '100%' }}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginComponent;
