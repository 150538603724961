import React, { useState, useEffect } from 'react';
import { getProductTypes } from '../util/functions';

// Create a context for the product types
export const ProductTypesContext = React.createContext();

export const ProductTypesProvider = ({ children }) => {
    const [productTypes, setProductTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchProductTypes = async () => {
        const fetchedProductTypes = await getProductTypes();
        setProductTypes(fetchedProductTypes);
        setLoading(false);
    };

    useEffect(() => {
        if (loading || (productTypes && productTypes.length)) return;
        setLoading(true);
        fetchProductTypes();
    }, [productTypes, loading]);

    return (
        <ProductTypesContext.Provider value={{
            productTypes,
            setProductTypes,
            fetchProductTypes,
            loading,
            setLoading,
        }}>
            {children}
        </ProductTypesContext.Provider>
    );
};
