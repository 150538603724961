import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

export const FooterComponent = () => (
  <Footer
    style={{
      textAlign: "center",
    }}
  >
    Rawass Trading© 2023
  </Footer>
);
